<template>
  <div v-if="switchersValues.length" class="switchers-block">
    <div
      v-for="(switcher, index) in switchersValues"
      :key="switcher.name + index"
      class="switchers-block__item"
    >
      <SwitcherButton
        :class="switcher.class"
        :is-switched="switcher.enabled"
        :name-of-option="switcher.name"
        :switch-info="switcher"
      />
    </div>
  </div>
</template>

<script>
import SwitcherButton from '../switcherButton/SwitcherButton';

export default {
  components: {
    SwitcherButton,
  },
  props: {
    switchersValues: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
@import './style.scss';
</style>
