export default [
  {
    name: 'Originality',
    class: false,
    enabled: false,
  },
  {
    name: 'Plot',
    class: false,
    enabled: false,
  },
  {
    name: 'Writing style',
    class: false,
    enabled: false,
  },
  {
    name: 'Emotion',
    class: false,
    enabled: false,
  },
];
