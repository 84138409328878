var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-create",class:{ 'review-create__first': _vm.isFirstReview }},[_c('section',{staticClass:"banner"},[_c('div',{staticClass:"container"},[_c('h2',{domProps:{"textContent":_vm._s(_vm.$t('Add a review'))}})])]),(_vm.book)?_c('div',{staticClass:"page__content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"review-create__header"},[_c('h3',[_vm._v(_vm._s(_vm.book.getTitle()))]),(_vm.book.hasAuthors())?_c('h5',[_vm._v(" by "+_vm._s(_vm.book.getAuthors().toString())+" ")]):_vm._e()]),_c('validation-observer',{ref:"form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return handleSubmit(_vm.create)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();}}},[_c('div',{staticClass:"row review-create__description"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"review-create__col"},[_c('h4',[_vm._v(_vm._s(_vm.$t('About the book')))]),(_vm.isFirstReview)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('About the book (first review)'))+" ")]):_c('p',{domProps:{"textContent":_vm._s(_vm.$t('About the book (second review)'))}})])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"review-create__col mt-2"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('div',{staticClass:"review-create__time"},[_c('div',{staticClass:"d-flex justify-content-sm-end"},[_c('validation-provider',{staticClass:"form-group form-group--horizontal mb-2",attrs:{"name":_vm.$t('From'),"rules":"numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"from"},domProps:{"textContent":_vm._s(_vm.$t('Time'))}}),_c('div',{staticClass:"form-validation"},[_c('the-mask',{staticClass:"form-control form-input--underline",attrs:{"id":"from","mask":"#####","type":"text","masked":false,"placeholder":_vm.$t('From')},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}}),(errors.length)?_c('span',{staticClass:"error-message t-nowrap",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"form-group review-create__epoch mb-2"},[_c('button',{class:{ checked: _vm.form.isFromBc },attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.$t('BC'))},on:{"click":function($event){_vm.form.isFromBc = true}}}),_c('button',{class:{ checked: !_vm.form.isFromBc },attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.$t('AD'))},on:{"click":function($event){_vm.form.isFromBc = false}}})])],1),_c('div',{staticClass:"d-flex justify-content-sm-end"},[_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"name":_vm.$t('To'),"rules":"numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"to"}}),_c('div',{staticClass:"form-validation"},[_c('the-mask',{staticClass:"form-control form-input--underline",attrs:{"id":"to","mask":"#####","type":"text","masked":false,"placeholder":_vm.$t('To')},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}}),(errors.length)?_c('span',{staticClass:"error-message t-nowrap",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"form-group review-create__epoch"},[_c('button',{class:{ checked: _vm.form.isToBc },attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.$t('BC'))},on:{"click":function($event){_vm.form.isToBc = true}}}),_c('button',{class:{ checked: !_vm.form.isToBc },attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.$t('AD'))},on:{"click":function($event){_vm.form.isToBc = false}}})])],1)]),_c('div',{staticClass:"review-create__year"},[_c('div',{staticClass:"form-group d-flex align-items-end mb-2"},[_c('basic-checkbox',{staticClass:"t-semibold",attrs:{"label":_vm.$t('Exact year'),"value":"isExactYear"},on:{"change":function($event){return _vm.setFocusOnExactYearInput($event)}},model:{value:(_vm.form.isExactYear),callback:function ($$v) {_vm.$set(_vm.form, "isExactYear", $$v)},expression:"form.isExactYear"}})],1),(_vm.form.isExactYear)?_c('div',{staticClass:"d-flex"},[_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"name":_vm.$t('Exact year'),"rules":"numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-validation"},[_c('the-mask',{ref:"exactYearInput",staticClass:"form-control form-input--underline",attrs:{"mask":"#####","type":"text","masked":false,"placeholder":_vm.$t('Year')},model:{value:(_vm.form.exactYear),callback:function ($$v) {_vm.$set(_vm.form, "exactYear", $$v)},expression:"form.exactYear"}}),(errors.length)?_c('span',{staticClass:"error-message t-nowrap",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"form-group review-create__epoch"},[_c('button',{class:{ checked: _vm.form.isExactYearBc },attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.$t('BC'))},on:{"click":function($event){_vm.form.isExactYearBc = true}}}),_c('button',{class:{ checked: !_vm.form.isExactYearBc },attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.$t('AD'))},on:{"click":function($event){_vm.form.isExactYearBc = false}}})])],1):_vm._e()])]),_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"name":_vm.$t('Place'),"detect-input":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"w-auto"},[_c('label',{staticClass:"form-label",attrs:{"for":"place"},domProps:{"textContent":_vm._s(_vm.$t('Place'))}})]),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('gmap-autocomplete',{staticClass:"field-map__input form-control form-input--underline",attrs:{"id":"place","value":_vm.place,"placeholder":_vm.$t('Type the location')},on:{"input":_vm.changePlace,"place_changed":function($event){return _vm.setPlace($event, validate)}}}),(_vm.form.places && _vm.form.places.length)?_c('div',{staticClass:"places__list"},_vm._l((_vm.form.places),function(pl){return _c('basic-tag',{key:pl.name,class:("" + (_vm.book.places.find(
                            function (ref) {
                                                var nameEn = ref.nameEn;
                                                var nameIt = ref.nameIt;
                                                var googlePlaceId = ref.googlePlaceId;

                                                return [
                                (nameEn || '').toLowerCase(),
                                (nameIt || '').toLowerCase() ].includes(pl.name.toLowerCase()) ||
                              googlePlaceId === pl.googlePlaceId;
}
                          )
                            ? ''
                            : 'is-new')),attrs:{"id":pl.name,"text":pl.name},on:{"deleteItem":function($event){return _vm.removePlace($event, validate)}}})}),1):_vm._e(),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],1)]}}],null,true)}),_c('validation-provider',{staticClass:"form-group form-group--horizontal book-tags",attrs:{"name":_vm.$t('Tags'),"detect-input":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var validate = ref.validate;
return [_c('div',{staticClass:"w-auto"},[_c('label',{staticClass:"form-label",attrs:{"for":"tags"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Tags')))])])]),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('div',{staticClass:"w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tag),expression:"tag"}],staticClass:"form-control form-input--underline",attrs:{"id":"tags","type":"text","placeholder":_vm.book.hasTags()
                            ? _vm.book.getTags().toArray().join(', ')
                            : _vm.$t('Tags placeholder')},domProps:{"value":(_vm.tag)},on:{"blur":function($event){return _vm.addTag($event, validate)},"change":function($event){return _vm.addTag($event, validate)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addTag($event, validate)},"input":function($event){if($event.target.composing){ return; }_vm.tag=$event.target.value}}}),_vm._l((_vm.form.tags),function(item){return _c('span',{key:item,class:("tag " + (_vm.book.getTags().toArray().includes(item)
                            ? ''
                            : 'is-new'))},[_c('span',{domProps:{"textContent":_vm._s(item)}}),_c('span',{staticClass:"tag__close",on:{"click":function($event){return _vm.removeTag(item, validate)}}})])})],2),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"form-group form-group--horizontal character-names",attrs:{"name":_vm.$t('Characters\' names'),"detect-input":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var validate = ref.validate;
return [_c('label',{staticClass:"form-label",attrs:{"for":"character-names"},domProps:{"textContent":_vm._s(_vm.$t('Characters\' names'))}}),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.characterName),expression:"characterName"}],ref:"characterNamesInput",staticClass:"form-control form-input--underline",attrs:{"id":"character-names","type":"text","placeholder":_vm.$t('Ex. Emma Bovary')},domProps:{"value":(_vm.characterName)},on:{"change":function($event){return _vm.addCharacterName($event, validate)},"blur":function($event){return _vm.addCharacterName($event, validate)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addCharacterName($event, validate)},"input":function($event){if($event.target.composing){ return; }_vm.characterName=$event.target.value}}}),_c('div',_vm._l((_vm.form.characterNames),function(name){return _c('span',{key:name,class:("tag " + (_vm.book.getCharacterNames().toArray().includes(name)
                            ? ''
                            : 'is-new'))},[_c('span',{domProps:{"textContent":_vm._s(name)}}),_c('span',{staticClass:"tag__close",on:{"click":function($event){return _vm.removeCharacterName(name, validate)}}})])}),0),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"form-group form-group--horizontal mt-4",attrs:{"name":_vm.$t('Author\'s nationality'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"slot":"label","for":"author-nationality"},domProps:{"textContent":_vm._s(_vm.$t('Author\'s nationality'))},on:{"click":_vm.setFocusOnNationality},slot:"label"}),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('MultiselectUnderline',_vm._g(_vm._b({staticClass:"form-select__underline w-100",attrs:{"id":"author-nationality","value":_vm.form.authorsNationality,"options":_vm.countries,"multiple":true,"taggable":false,"placeholder":_vm.$t('Select nationality')},on:{"tag":_vm.addNationality},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option))])])]}},{key:"tag",fn:function(props){return [_c('span',{class:("multiselect__tag " + (_vm.book.getAuthorNationality() === props.option
                              ? ''
                              : 'is-new'))},[_c('span',[_vm._v(" "+_vm._s(props.option)+" ")]),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"click":function($event){return props.remove(props.option)}}})])]}}],null,true),model:{value:(_vm.form.authorsNationality),callback:function ($$v) {_vm.$set(_vm.form, "authorsNationality", $$v)},expression:"form.authorsNationality"}},'MultiselectUnderline',_vm.$attrs,false),_vm.$listeners)),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"form-group d-flex align-items-center mb-0"},[_c('basic-checkbox',{staticClass:"t-bold",attrs:{"label":_vm.$t('True events'),"value":"isTrueEvents"},on:{"change":function($event){return _vm.setFocus($event, 'trueEventsInput')}},model:{value:(_vm.form.isTrueEvents),callback:function ($$v) {_vm.$set(_vm.form, "isTrueEvents", $$v)},expression:"form.isTrueEvents"}}),_c('TooltipInfo',{attrs:{"content":_vm.$t('True events (info)')}})],1),(_vm.form.isTrueEvents)?_c('validation-provider',{staticClass:"form-group mt-1 mb-0",attrs:{"name":_vm.$t('True events'),"detect-input":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.trueEvent),expression:"trueEvent"}],ref:"trueEventsInput",staticClass:"form-control form-input--underline",attrs:{"id":"search-real-event","type":"text"},domProps:{"value":(_vm.trueEvent)},on:{"blur":function($event){return _vm.addTrueEvent($event, validate)},"change":function($event){return _vm.addTrueEvent($event, validate)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addTrueEvent($event, validate)},"input":function($event){if($event.target.composing){ return; }_vm.trueEvent=$event.target.value}}}),_c('div',_vm._l((_vm.form.trueEvents),function(event){return _c('span',{key:event,class:("tag " + (_vm.book.getTrueEvents().toArray().includes(event)
                              ? ''
                              : 'is-new'))},[_c('span',{domProps:{"textContent":_vm._s(event)}}),_c('span',{staticClass:"tag__close",on:{"click":function($event){return _vm.removeTrueEvent(event, validate)}}})])}),0),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"form-group d-flex align-items-center mb-0"},[_c('basic-checkbox',{staticClass:"t-bold",attrs:{"label":_vm.$t('Real characters'),"value":"isRealCharacters"},on:{"change":function($event){return _vm.setFocus($event, 'realCharactersInput')}},model:{value:(_vm.form.isRealCharacters),callback:function ($$v) {_vm.$set(_vm.form, "isRealCharacters", $$v)},expression:"form.isRealCharacters"}}),_c('TooltipInfo',{attrs:{"content":_vm.$t('Real characters (info)')}})],1),(_vm.form.isRealCharacters)?_c('validation-provider',{staticClass:"form-group mt-1 mb-0",attrs:{"name":_vm.$t('Real characters'),"detect-input":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.realCharacter),expression:"realCharacter"}],ref:"realCharactersInput",staticClass:"form-control form-input--underline",attrs:{"id":"search-real-characters","type":"text"},domProps:{"value":(_vm.realCharacter)},on:{"blur":function($event){return _vm.addRealCharacter($event, validate)},"change":function($event){return _vm.addRealCharacter($event, validate)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addRealCharacter($event, validate)},"input":function($event){if($event.target.composing){ return; }_vm.realCharacter=$event.target.value}}}),_c('div',_vm._l((_vm.form.realCharacters),function(item){return _c('span',{key:item,class:("tag " + (_vm.book.getRealCharacters().toArray().includes(item)
                              ? ''
                              : 'is-new'))},[_c('span',{domProps:{"textContent":_vm._s(item)}}),_c('span',{staticClass:"tag__close",on:{"click":function($event){return _vm.removeRealCharacter(item, validate)}}})])}),0),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"form-group d-flex align-items-center mb-0"},[_c('basic-checkbox',{staticClass:"t-bold",attrs:{"label":_vm.$t('Screen adaptation'),"value":"isScreenAdaptations"},on:{"change":function($event){return _vm.setFocus($event, 'screenAdaptationInput')}},model:{value:(_vm.form.isScreenAdaptations),callback:function ($$v) {_vm.$set(_vm.form, "isScreenAdaptations", $$v)},expression:"form.isScreenAdaptations"}}),_c('TooltipInfo',{attrs:{"content":_vm.$t('Screen adaptations (info)')}})],1),(_vm.form.isScreenAdaptations)?_c('validation-provider',{attrs:{"name":_vm.$t('Screen adaptations'),"detect-input":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_vm._l((_vm.screenAdaptationInputs),function(input){return _c('screen-adaptation-input',{key:input,staticClass:"mt-3",on:{"add":function($event){return _vm.addScreenAdaptation($event, validate)},"remove":function($event){return _vm.removeScreenAdaptation($event, validate)}}})}),(_vm.screenAdaptationInputs < 5)?_c('button',{staticClass:"btn--adaptation",attrs:{"type":"button"},on:{"click":_vm.addScreenAdaptationInput}},[_c('svg',{attrs:{"width":"37","height":"37","viewBox":"0 0 37 37","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"0.5","y":"0.5","width":"35.5238","height":"35.5238","stroke":"#F7B058"}}),_c('path',{attrs:{"d":"M18 19H10M18 27V19V27ZM18 19V11V19ZM18 19H26H18Z","stroke":"#F7B058","stroke-width":"2"}})])]):_vm._e(),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],2)]}}],null,true)}):_vm._e()],1)],1)])]),_c('div',{staticClass:"row review-create__rating"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"review-create__col"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Your review')))]),_c('p',[_vm._v(_vm._s(_vm.$t('Your review (description)')))])])]),_c('div',{staticClass:"col-12 col-lg-6"},[(_vm.book)?_c('div',{staticClass:"review-create__col mt-3"},[_c('SwitcherRating',{attrs:{"switchers-values":_vm.form.ratingValues}}),_c('validation-provider',{staticClass:"form-group review-create__text",attrs:{"rules":"max:500","name":_vm.$t('Review'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"form-label mb-2",attrs:{"for":"comment"}},[_vm._v(" "+_vm._s(_vm.$t('Your review'))+": ")]),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.comment),expression:"form.comment"}],staticClass:"form-control",attrs:{"id":"comment","name":"comment","rows":"10","maxlength":"500","placeholder":_vm.$t('Write a genuine review of the book')},domProps:{"value":(_vm.form.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "comment", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"form-group review-create__recommended",attrs:{"name":_vm.$t('Recommended'),"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('div',{staticClass:"form-group__label"},[_vm._v(" "+_vm._s(_vm.$t('Recommended for'))+": ")]),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('div',{staticClass:"form-check"},_vm._l((_vm.recommendedFor),function(group){return _c('label',{key:group,staticClass:"form-check__label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recommended),expression:"form.recommended"}],staticClass:"form-check__input",attrs:{"type":"checkbox"},domProps:{"value":group,"checked":Array.isArray(_vm.form.recommended)?_vm._i(_vm.form.recommended,group)>-1:(_vm.form.recommended)},on:{"change":function($event){var $$a=_vm.form.recommended,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=group,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "recommended", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "recommended", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "recommended", $$c)}}}}),_c('span',{staticClass:"form-check__indicator"}),_vm._v(" "+_vm._s(_vm.$t(group))+" ")])}),0),(errors.length)?_c('span',{staticClass:"error-message",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)})],1):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"review-create__col"})]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"review-create__col"},[_c('button',{staticClass:"btn btn--primary btn--review",class:{
                    'btn--disabled':
                      (validated && invalid) || _vm.loading || !_vm.isFormChanged,
                  },attrs:{"type":"submit","disabled":(validated && invalid) || _vm.loading || !_vm.isFormChanged}},[_vm._v(" "+_vm._s(_vm.isFirstReview ? _vm.$t('Add a review') : _vm.$t('Add a review (second review)'))+" ")])])])])])]}}],null,false,2063884843)})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }