<template>
  <div class="review-create" :class="{ 'review-create__first': isFirstReview }">
    <section class="banner">
      <div class="container">
        <h2 v-text="$t('Add a review')"></h2>
      </div>
    </section>
    <div v-if="book" class="page__content">
      <div class="container">
        <div class="review-create__header">
          <h3>{{ book.getTitle() }}</h3>
          <h5 v-if="book.hasAuthors()">
            by {{ book.getAuthors().toString() }}
          </h5>
        </div>
        <validation-observer
          v-slot="{ handleSubmit, invalid, validated }"
          ref="form"
          tag="div"
        >
          <form
            @submit.prevent.stop="handleSubmit(create)"
            @keydown.enter.prevent.stop
          >
            <div class="row review-create__description">
              <div class="col-12 col-lg-6">
                <div class="review-create__col">
                  <h4>{{ $t('About the book') }}</h4>
                  <p v-if="isFirstReview">
                    {{ $t('About the book (first review)') }}
                  </p>
                  <p v-else v-text="$t('About the book (second review)')"></p>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="review-create__col mt-2">
                  <div class="d-flex flex-column flex-sm-row">
                    <div class="review-create__time">
                      <div class="d-flex justify-content-sm-end">
                        <validation-provider
                          v-slot="{ errors }"
                          class="form-group form-group--horizontal mb-2"
                          :name="$t('From')"
                          rules="numeric"
                          tag="div"
                        >
                          <label
                            class="form-label"
                            for="from"
                            v-text="$t('Time')"
                          ></label>
                          <div class="form-validation">
                            <the-mask
                              id="from"
                              v-model="form.from"
                              mask="#####"
                              type="text"
                              :masked="false"
                              :placeholder="$t('From')"
                              class="form-control form-input--underline"
                            ></the-mask>
                            <span
                              v-if="errors.length"
                              class="error-message t-nowrap"
                              v-text="errors[0]"
                            ></span>
                          </div>
                        </validation-provider>
                        <div class="form-group review-create__epoch mb-2">
                          <button
                            type="button"
                            :class="{ checked: form.isFromBc }"
                            @click="form.isFromBc = true"
                            v-text="$t('BC')"
                          ></button>
                          <button
                            type="button"
                            :class="{ checked: !form.isFromBc }"
                            @click="form.isFromBc = false"
                            v-text="$t('AD')"
                          ></button>
                        </div>
                      </div>
                      <div class="d-flex justify-content-sm-end">
                        <validation-provider
                          v-slot="{ errors }"
                          class="form-group form-group--horizontal"
                          :name="$t('To')"
                          rules="numeric"
                          tag="div"
                        >
                          <label for="to" class="form-label"></label>
                          <div class="form-validation">
                            <the-mask
                              id="to"
                              v-model="form.to"
                              mask="#####"
                              type="text"
                              :masked="false"
                              :placeholder="$t('To')"
                              class="form-control form-input--underline"
                            ></the-mask>
                            <span
                              v-if="errors.length"
                              class="error-message t-nowrap"
                              v-text="errors[0]"
                            ></span>
                          </div>
                        </validation-provider>
                        <div class="form-group review-create__epoch">
                          <button
                            type="button"
                            :class="{ checked: form.isToBc }"
                            @click="form.isToBc = true"
                            v-text="$t('BC')"
                          ></button>
                          <button
                            type="button"
                            :class="{ checked: !form.isToBc }"
                            @click="form.isToBc = false"
                            v-text="$t('AD')"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div class="review-create__year">
                      <div class="form-group d-flex align-items-end mb-2">
                        <basic-checkbox
                          v-model="form.isExactYear"
                          class="t-semibold"
                          :label="$t('Exact year')"
                          value="isExactYear"
                          @change="setFocusOnExactYearInput($event)"
                        ></basic-checkbox>
                      </div>
                      <div v-if="form.isExactYear" class="d-flex">
                        <validation-provider
                          v-slot="{ errors }"
                          class="form-group form-group--horizontal"
                          :name="$t('Exact year')"
                          rules="numeric"
                          tag="div"
                        >
                          <div class="form-validation">
                            <the-mask
                              ref="exactYearInput"
                              v-model="form.exactYear"
                              mask="#####"
                              type="text"
                              :masked="false"
                              :placeholder="$t('Year')"
                              class="form-control form-input--underline"
                            ></the-mask>
                            <span
                              v-if="errors.length"
                              class="error-message t-nowrap"
                              v-text="errors[0]"
                            ></span>
                          </div>
                        </validation-provider>
                        <div class="form-group review-create__epoch">
                          <button
                            type="button"
                            :class="{ checked: form.isExactYearBc }"
                            @click="form.isExactYearBc = true"
                            v-text="$t('BC')"
                          ></button>
                          <button
                            type="button"
                            :class="{ checked: !form.isExactYearBc }"
                            @click="form.isExactYearBc = false"
                            v-text="$t('AD')"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <validation-provider
                    v-slot="{ errors, validate }"
                    class="form-group form-group--horizontal"
                    :name="$t('Place')"
                    :detect-input="false"
                    tag="div"
                  >
                    <div class="w-auto">
                      <label
                        for="place"
                        class="form-label"
                        v-text="$t('Place')"
                      >
                      </label>
                    </div>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <gmap-autocomplete
                        id="place"
                        class="field-map__input form-control form-input--underline"
                        :value="place"
                        :placeholder="$t('Type the location')"
                        @input="changePlace"
                        @place_changed="setPlace($event, validate)"
                      >
                      </gmap-autocomplete>
                      <div
                        v-if="form.places && form.places.length"
                        class="places__list"
                      >
                        <basic-tag
                          v-for="pl in form.places"
                          :id="pl.name"
                          :key="pl.name"
                          :text="pl.name"
                          :class="`${
                            book.places.find(
                              ({ nameEn, nameIt, googlePlaceId }) =>
                                [
                                  (nameEn || '').toLowerCase(),
                                  (nameIt || '').toLowerCase(),
                                ].includes(pl.name.toLowerCase()) ||
                                googlePlaceId === pl.googlePlaceId
                            )
                              ? ''
                              : 'is-new'
                          }`"
                          @deleteItem="removePlace($event, validate)"
                        ></basic-tag>
                      </div>
                      <span
                        v-if="errors.length"
                        class="error-message"
                        v-text="errors[0]"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors, validate }"
                    class="form-group form-group--horizontal book-tags"
                    :name="$t('Tags')"
                    :detect-input="false"
                    tag="div"
                  >
                    <div class="w-auto">
                      <label for="tags" class="form-label">
                        <span>{{ $t('Tags') }}</span>
                      </label>
                    </div>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <div class="w-100">
                        <input
                          id="tags"
                          v-model="tag"
                          type="text"
                          class="form-control form-input--underline"
                          :placeholder="
                            book.hasTags()
                              ? book.getTags().toArray().join(', ')
                              : $t('Tags placeholder')
                          "
                          @blur="addTag($event, validate)"
                          @change="addTag($event, validate)"
                          @keydown.enter.prevent="addTag($event, validate)"
                        />
                        <span
                          v-for="item in form.tags"
                          :key="item"
                          :class="`tag ${
                            book.getTags().toArray().includes(item)
                              ? ''
                              : 'is-new'
                          }`"
                        >
                          <span v-text="item"></span>
                          <span
                            class="tag__close"
                            @click="removeTag(item, validate)"
                          ></span>
                        </span>
                      </div>
                      <span
                        v-if="errors.length"
                        class="error-message"
                        v-text="errors[0]"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors, validate }"
                    class="form-group form-group--horizontal character-names"
                    :name="$t('Characters\' names')"
                    :detect-input="false"
                    tag="div"
                  >
                    <label
                      for="character-names"
                      class="form-label"
                      v-text="$t('Characters\' names')"
                    >
                    </label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <input
                        id="character-names"
                        ref="characterNamesInput"
                        v-model="characterName"
                        type="text"
                        class="form-control form-input--underline"
                        :placeholder="$t('Ex. Emma Bovary')"
                        @change="addCharacterName($event, validate)"
                        @blur="addCharacterName($event, validate)"
                        @keydown.enter.prevent="
                          addCharacterName($event, validate)
                        "
                      />
                      <div>
                        <span
                          v-for="name in form.characterNames"
                          :key="name"
                          :class="`tag ${
                            book.getCharacterNames().toArray().includes(name)
                              ? ''
                              : 'is-new'
                          }`"
                        >
                          <span v-text="name" />
                          <span
                            class="tag__close"
                            @click="removeCharacterName(name, validate)"
                          ></span>
                        </span>
                      </div>
                      <span
                        v-if="errors.length"
                        class="error-message"
                        v-text="errors[0]"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group form-group--horizontal mt-4"
                    :name="$t('Author\'s nationality')"
                    tag="div"
                  >
                    <label
                      slot="label"
                      class="form-label"
                      for="author-nationality"
                      @click="setFocusOnNationality"
                      v-text="$t('Author\'s nationality')"
                    ></label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <MultiselectUnderline
                        id="author-nationality"
                        v-model="form.authorsNationality"
                        class="form-select__underline w-100"
                        :value="form.authorsNationality"
                        :options="countries"
                        v-bind="$attrs"
                        :multiple="true"
                        :taggable="false"
                        :placeholder="$t('Select nationality')"
                        v-on="$listeners"
                        @tag="addNationality"
                      >
                        <template slot="option" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{
                              props.option
                            }}</span>
                          </span>
                        </template>
                        <template slot="tag" slot-scope="props">
                          <span
                            :class="`multiselect__tag ${
                              book.getAuthorNationality() === props.option
                                ? ''
                                : 'is-new'
                            }`"
                          >
                            <span>
                              {{ props.option }}
                            </span>
                            <i
                              aria-hidden="true"
                              tabindex="1"
                              class="multiselect__tag-icon"
                              @click="props.remove(props.option)"
                            ></i>
                          </span>
                        </template>
                      </MultiselectUnderline>
                      <span
                        v-if="errors.length"
                        class="error-message"
                        v-text="errors[0]"
                      />
                    </div>
                  </validation-provider>
                  <div class="mt-4">
                    <div class="form-group d-flex align-items-center mb-0">
                      <basic-checkbox
                        v-model="form.isTrueEvents"
                        class="t-bold"
                        :label="$t('True events')"
                        value="isTrueEvents"
                        @change="setFocus($event, 'trueEventsInput')"
                      ></basic-checkbox>
                      <TooltipInfo :content="$t('True events (info)')" />
                    </div>
                    <validation-provider
                      v-if="form.isTrueEvents"
                      v-slot="{ errors, validate }"
                      class="form-group mt-1 mb-0"
                      :name="$t('True events')"
                      :detect-input="false"
                      tag="div"
                    >
                      <div
                        class="form-validation"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <input
                          id="search-real-event"
                          ref="trueEventsInput"
                          v-model="trueEvent"
                          type="text"
                          class="form-control form-input--underline"
                          @blur="addTrueEvent($event, validate)"
                          @change="addTrueEvent($event, validate)"
                          @keydown.enter.prevent="
                            addTrueEvent($event, validate)
                          "
                        />
                        <div>
                          <span
                            v-for="event in form.trueEvents"
                            :key="event"
                            :class="`tag ${
                              book.getTrueEvents().toArray().includes(event)
                                ? ''
                                : 'is-new'
                            }`"
                          >
                            <span v-text="event"></span>
                            <span
                              class="tag__close"
                              @click="removeTrueEvent(event, validate)"
                            ></span>
                          </span>
                        </div>
                        <span
                          v-if="errors.length"
                          class="error-message"
                          v-text="errors[0]"
                        />
                      </div>
                    </validation-provider>
                  </div>
                  <div class="mt-4">
                    <div class="form-group d-flex align-items-center mb-0">
                      <basic-checkbox
                        v-model="form.isRealCharacters"
                        class="t-bold"
                        :label="$t('Real characters')"
                        value="isRealCharacters"
                        @change="setFocus($event, 'realCharactersInput')"
                      ></basic-checkbox>
                      <TooltipInfo :content="$t('Real characters (info)')" />
                    </div>
                    <validation-provider
                      v-if="form.isRealCharacters"
                      v-slot="{ errors, validate }"
                      class="form-group mt-1 mb-0"
                      :name="$t('Real characters')"
                      :detect-input="false"
                      tag="div"
                    >
                      <div
                        class="form-validation"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <input
                          id="search-real-characters"
                          ref="realCharactersInput"
                          v-model="realCharacter"
                          type="text"
                          class="form-control form-input--underline"
                          @blur="addRealCharacter($event, validate)"
                          @change="addRealCharacter($event, validate)"
                          @keydown.enter.prevent="
                            addRealCharacter($event, validate)
                          "
                        />
                        <div>
                          <span
                            v-for="item in form.realCharacters"
                            :key="item"
                            :class="`tag ${
                              book.getRealCharacters().toArray().includes(item)
                                ? ''
                                : 'is-new'
                            }`"
                          >
                            <span v-text="item"></span>
                            <span
                              class="tag__close"
                              @click="removeRealCharacter(item, validate)"
                            ></span>
                          </span>
                        </div>
                        <span
                          v-if="errors.length"
                          class="error-message"
                          v-text="errors[0]"
                        />
                      </div>
                    </validation-provider>
                  </div>
                  <div class="my-4">
                    <div class="form-group d-flex align-items-center mb-0">
                      <basic-checkbox
                        v-model="form.isScreenAdaptations"
                        class="t-bold"
                        :label="$t('Screen adaptation')"
                        value="isScreenAdaptations"
                        @change="setFocus($event, 'screenAdaptationInput')"
                      ></basic-checkbox>
                      <TooltipInfo :content="$t('Screen adaptations (info)')" />
                    </div>
                    <validation-provider
                      v-if="form.isScreenAdaptations"
                      v-slot="{ errors, validate }"
                      :name="$t('Screen adaptations')"
                      :detect-input="false"
                      tag="div"
                    >
                      <div
                        class="form-validation"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <screen-adaptation-input
                          v-for="input in screenAdaptationInputs"
                          :key="input"
                          class="mt-3"
                          @add="addScreenAdaptation($event, validate)"
                          @remove="removeScreenAdaptation($event, validate)"
                        />
                        <button
                          v-if="screenAdaptationInputs < 5"
                          type="button"
                          class="btn--adaptation"
                          @click="addScreenAdaptationInput"
                        >
                          <svg
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="35.5238"
                              height="35.5238"
                              stroke="#F7B058"
                            />
                            <path
                              d="M18 19H10M18 27V19V27ZM18 19V11V19ZM18 19H26H18Z"
                              stroke="#F7B058"
                              stroke-width="2"
                            />
                          </svg>
                        </button>
                        <span
                          v-if="errors.length"
                          class="error-message"
                          v-text="errors[0]"
                        ></span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
            <div class="row review-create__rating">
              <div class="col-12 col-lg-6">
                <div class="review-create__col">
                  <h4>{{ $t('Your review') }}</h4>
                  <p>{{ $t('Your review (description)') }}</p>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div v-if="book" class="review-create__col mt-3">
                  <SwitcherRating :switchers-values="form.ratingValues" />

                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group review-create__text"
                    rules="max:500"
                    :name="$t('Review')"
                    tag="div"
                  >
                    <label for="comment" class="form-label mb-2">
                      {{ $t('Your review') }}:
                    </label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <textarea
                        id="comment"
                        v-model="form.comment"
                        class="form-control"
                        name="comment"
                        rows="10"
                        maxlength="500"
                        :placeholder="$t('Write a genuine review of the book')"
                      ></textarea>
                      <span
                        v-if="errors.length"
                        class="error-message"
                        v-text="errors[0]"
                      />
                    </div>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group review-create__recommended"
                    :name="$t('Recommended')"
                    rules=""
                    tag="div"
                  >
                    <div class="form-group__label">
                      {{ $t('Recommended for') }}:
                    </div>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <div class="form-check">
                        <label
                          v-for="group in recommendedFor"
                          :key="group"
                          class="form-check__label"
                        >
                          <input
                            v-model="form.recommended"
                            class="form-check__input"
                            type="checkbox"
                            :value="group"
                          />
                          <span class="form-check__indicator"></span>
                          {{ $t(group) }}
                        </label>
                      </div>
                      <span
                        v-if="errors.length"
                        class="error-message"
                        v-text="errors[0]"
                      />
                    </div>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="review-create__col"></div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="review-create__col">
                  <button
                    type="submit"
                    class="btn btn--primary btn--review"
                    :disabled="
                      (validated && invalid) || loading || !isFormChanged
                    "
                    :class="{
                      'btn--disabled':
                        (validated && invalid) || loading || !isFormChanged,
                    }"
                  >
                    {{
                      isFirstReview
                        ? $t('Add a review')
                        : $t('Add a review (second review)')
                    }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import book from '@/api/book';
import countries from '@/api/nationalities';
import reviews from '@/api/review';
import RECOMMENDED_FOR from '@/constants/recommendedFor';
import BasicTag from '@/components/ui/basicTag/BasicTag';
import BasicCheckbox from '@/components/ui/basic-checkbox/BasicCheckbox';
import { TheMask } from 'vue-the-mask';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import MultiselectUnderline from '@/components/ui/multiselect/MultiselectUnderline';
import TooltipInfo from '@/components/ui/tooltipInfo/TooltipInfo';
import ScreenAdaptationInput from './ScreenAdaptation';
import SwitcherRating from '../../../components/ui/switcherRating/SwitcherRating';
import ratingSwitchers from '../../../constants/ratingSwitchers';

const VALUABLE_FORM_KEYS = [
  'authorsNationality',
  'places',
  'tags',
  'genre',
  'realCharacters',
  'trueEvents',
  'characterNames',
  'screenAdaptations',
  'exactYear',
  'from',
  'to',
  'comment',
  'recommended',
  'ratingValues',
];

export default {
  components: {
    BasicTag,
    BasicCheckbox,
    MultiselectUnderline,
    ScreenAdaptationInput,
    TheMask,
    TooltipInfo,
    ValidationObserver,
    ValidationProvider,
    SwitcherRating,
  },

  data() {
    return {
      book: null,
      loading: false,
      initialForm: {},
      form: {
        authorsNationality: [],
        places: [],
        tags: [],
        genre: [],
        realCharacters: [],
        trueEvents: [],
        characterNames: [],
        screenAdaptations: [],
        isTrueEvents: false,
        isRealCharacters: false,
        isScreenAdaptations: false,
        isExactYear: false,
        exactYear: null,
        from: null,
        to: null,
        isFromBc: false,
        isToBc: false,
        isExactYearBc: false,
        comment: '',
        recommended: [],
        ratingValues: ratingSwitchers.map((switcher) => ({
          ...switcher,
          name: this.$t(switcher.name),
        })),
      },
      place: '',
      tag: '',
      characterName: '',
      trueEvent: '',
      realCharacter: '',
      countries: [],
      recommendedFor: RECOMMENDED_FOR,
      screenAdaptationInputs: 0,
    };
  },

  beforeUnmount() {
    this.form.ratingValues = ratingSwitchers.map((switcher) => ({
      ...switcher,
      name: this.$t(switcher.name),
    }));
  },

  computed: {
    isFirstReview() {
      return !this.book || !this.book.hasReviews();
    },

    isFormChanged() {
      return !isEqual(
        pick(this.form, VALUABLE_FORM_KEYS),
        pick(this.initialForm, VALUABLE_FORM_KEYS)
      );
    },
  },

  async created() {
    await this.fetchData();
    this.fillForm();
  },

  methods: {
    async fetchData() {
      const [bookData, countriesData] = await Promise.all([
        this.fetchBookPromise(),
        this.fetchCountryPromise(),
      ]);

      this.book = bookData;
      this.countries = Object.values(countriesData);
    },

    fetchBookPromise() {
      const { isbn } = this.$route.params;
      const { googleId } = this.$route.query;
      return book.findByIsbnOrId(isbn, googleId);
    },

    fetchCountryPromise() {
      return countries.get();
    },

    fillForm() {
      this.fillAuthorNationality();
      const isEmulatedExactYear = !!this.book.timeFrom &&
        this.book.timeFrom === this.book.timeTo &&
        this.book.isFromBc === this.book.isToBc;

      this.form = {
        ...this.form,
        places: this.book.places.map(({ nameEn, nameIt, googlePlaceId }) => ({
          name: (this.$i18n.locale === 'en' ? nameEn : nameIt) || '',
          googlePlaceId,
        })),
        from: this.book.timeFrom,
        to: this.book.timeTo,
        isExactYear: this.book.isExactYear || isEmulatedExactYear,
        exactYear:
          this.book.exactYear ||
          (isEmulatedExactYear ? this.book.timeFrom : undefined),
        isExactYearBc:
          this.book.isExactYearBc ||
          (isEmulatedExactYear ? this.book.isFromBc : undefined),
        tags: [...this.book.getTags().toArray()],
        characterNames: [...this.book.getCharacterNames().toArray()],
        realCharacters: [...this.book.getRealCharacters().toArray()],
        trueEvents: [...this.book.getTrueEvents().toArray()],
        isTrueEvents: this.book.hasTrueEvents(),
        isRealCharacters: this.book.hasRealCharacters(),
      };

      this.initialForm = cloneDeep(this.form);
    },

    fillAuthorNationality() {
      if (this.book.hasAuthorNationality()) {
        this.form.authorsNationality = [this.book.getAuthorNationality()];
      }
    },

    updateForm(data = {}) {
      this.form = Object.assign(this.form, data);
    },

    async create() {
      if (this.loading || !this.isFormChanged) {
        return;
      }

      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      const rateScheme = [
        'rateOriginality',
        'ratePlot',
        'rateWritingStyle',
        'rateEmotion',
      ];
      const rateSchemeToUpload = {};

      this.form.ratingValues
        .map((rateItem) => rateItem.enabled)
        .forEach((rateValue, rateIndex) => {
          rateSchemeToUpload[rateScheme[rateIndex]] = rateValue;
        });

      this.loading = true;

      const authorsNationality = this.form.authorsNationality.length
        ? this.form.authorsNationality[0]
        : undefined;

      const form = {
        ...this.form,
        authorsNationality,
        ...rateSchemeToUpload,
      };

      try {
        await reviews.create(this.$route.params.isbn, form);

        this.$router.push({ name: 'reviews.thank-you' });
      } catch (e) {
        // console.log(e);
        this.loading = false;
      }
    },

    setPlace(event, validate) {
      const { address_components: components } = event;

      if (!components) {
        return;
      }

      const country = components.find(({ types }) => types.includes('country'));
      const postalTown = components.find(({ types }) =>
        types.includes('locality')
      );
      const countryName = country ? country.long_name : '';
      const postalTownName = postalTown ? postalTown.long_name : '';
      this.form.places.push({
        name: `${postalTownName} ${countryName}`.trim(),
        googlePlaceId: event.place_id,
      });
      this.place = '';
      // TODO use googlePlaceId instead after 01.08.2022
      validate(this.form.places.map((place) => place.name));
    },

    removePlace(name, validate) {
      // TODO use googlePlaceId instead after 01.08.2022
      const index = this.form.places.findIndex(
        (place) => (place.name || '').toLowerCase() === name.toLowerCase()
      );
      this.form.places.splice(index, 1);
      this.form.googlePlaceIds.splice(index, 1);

      validate(this.form.places.map((place) => place.name));
    },

    changePlace(event) {
      this.place = event.target.value;
    },

    async addTag(e, validate) {
      const tag = this.tag.trim();

      if (this.empty(tag)) {
        return;
      }

      if (!this.isTagExists(tag)) {
        this.form.tags.push(this.tag);
      }

      this.tag = '';

      validate(this.form.tags);
    },

    removeTag(tag, validate) {
      this.form.tags = this.form.tags.filter((item) => item !== tag);

      validate(this.form.tags);
    },

    isTagExists(tag) {
      return this.form.tags.some((item) => item === tag);
    },

    addCharacterName(e, validate) {
      const name = this.characterName.trim();

      if (this.empty(name)) {
        return;
      }

      if (!this.isCharacterNameExists(name)) {
        this.form.characterNames.push(this.characterName);
      }

      this.characterName = '';

      validate(this.form.characterNames);
    },

    removeCharacterName(name, validate) {
      this.form.characterNames = this.form.characterNames.filter(
        (item) => item !== name
      );

      validate(this.form.characterNames);
    },

    isCharacterNameExists(name) {
      return this.form.characterNames.some((item) => item === name);
    },

    addTrueEvent(e, validate) {
      const name = this.trueEvent.trim();

      if (this.empty(name)) {
        return;
      }

      if (!this.isTrueEventExists(name)) {
        this.form.trueEvents.push(this.trueEvent);
      }

      this.trueEvent = '';

      validate(this.form.trueEvents);
    },

    removeTrueEvent(event, validate) {
      this.form.trueEvents = this.form.trueEvents.filter(
        (item) => item !== event
      );

      validate(this.form.trueEvents);
    },

    isTrueEventExists(event) {
      return this.form.trueEvents.some((item) => item === event);
    },

    addRealCharacter(e, validate) {
      const name = this.realCharacter.trim();

      if (this.empty(name)) {
        return;
      }

      if (!this.isRealCharacterExists(name)) {
        this.form.realCharacters.push(this.realCharacter);
      }

      this.realCharacter = '';

      validate(this.form.realCharacters);
    },

    removeRealCharacter(name, validate) {
      this.form.realCharacters = this.form.realCharacters.filter(
        (item) => item !== name
      );

      validate(this.form.realCharacters);
    },

    isRealCharacterExists(name) {
      return this.form.realCharacters.some((item) => item === name);
    },

    addNationality(country) {
      this.form.authorsNationality.push(country);
    },

    addScreenAdaptation(adaptation, validate) {
      if (!this.screenAdaptationExists(adaptation)) {
        this.form.screenAdaptations.push(adaptation);
      }

      validate(this.form.screenAdaptations);
    },

    removeScreenAdaptation(adaptation, validate) {
      this.form.screenAdaptations = this.form.screenAdaptations.filter(
        (item) => {
          return (
            item.name !== adaptation.name ||
            item.category !== adaptation.category
          );
        }
      );

      validate(this.form.screenAdaptations);
    },

    screenAdaptationExists(adaptation) {
      return this.form.screenAdaptations.some((item) => {
        return (
          item.name === adaptation.name && item.category === adaptation.category
        );
      });
    },

    addScreenAdaptationInput() {
      if (this.screenAdaptationInputs < 5) {
        this.screenAdaptationInputs += 1;
      }
    },

    empty(value) {
      return value === null || value === undefined || value === '';
    },

    setFocusOnExactYearInput(opened) {
      if (opened) {
        this.$nextTick(() => this.$refs.exactYearInput.$el.focus());
      }
    },

    setFocusOnNationality() {
      this.$nextTick(() => {
        const el = this.$el.querySelector('#author-nationality input');

        if (el) {
          el.focus();
        }
      });
    },

    setFocus(opened, ref) {
      if (opened) {
        this.$nextTick(() => this.$refs[ref] && this.$refs[ref].focus());
      }
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './create.scss';
</style>
