<template>
  <div class="review-created">
    <section class="banner">
      <div class="container"></div>
    </section>
    <div class="page__content pb-0">
      <div class="container">
        <div class="d-flex flex-column flex-md-row justify-content-between">
          <div class="review-created__image">
            <img src="@/assets/img/review_created.svg" alt="$t('Book')" />
          </div>
          <div>
            <div class="review-created__header">
              <h3 v-text="$t('Thank you for your review!')"></h3>
              <h3 v-text="$t('There is one more book in our database!')"></h3>
            </div>
            <div class="review-created__count">
              <span>{{ count }} {{ $t('books') }}</span>
              <p v-text="$t('catalogued until now')"></p>
            </div>
            <p
              class="review-created__description"
              v-text="$t('Thank you (description)')"
            >
              It didn't take too long to write your review, right? Add more
              books now, this will increase the chances to find the right book!
            </p>
          </div>
        </div>
      </div>
      <search-advance />
    </div>
  </div>
</template>

<script>
import books from '@/api/books';
import SearchAdvance from '../../home/searchAdvance/SearchAdvance';

export default {
  name: 'ReviewCreated',

  components: {
    SearchAdvance,
  },

  data() {
    return {
      count: 0,
    };
  },

  created() {
    this.countBooks();
  },

  methods: {
    async countBooks() {
      this.count = await books.count();
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './thankYou.scss';
</style>
