<template>
  <div :class="switcherClass">
    <div class="base-switcher__text">
      {{ nameOfOption }}
    </div>
    <switches
      v-model="switchInfo.enabled"
      theme="custom"
      color="blue"
    ></switches>
  </div>
</template>

<script>
import Switches from 'vue-switches';

export default {
  components: {
    Switches,
  },
  props: ['name-of-option', 'is-switched', 'switchInfo'],
  computed: {
    switcherClass() {
      return `base-switcher ${this.class || ''}`;
    },
  },
};
</script>

<style lang="scss">
@import './style.scss';
</style>
