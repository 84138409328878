<template>
  <div class="form-group d-flex mt-1 mb-0">
    <SingleSelectUnderline
      v-model="category"
      class="screen-adaptation__select"
      track-by="id"
      label="name"
      :options="categories"
      :allow-empty="false"
    />
    <div class="ml-2 w-100">
      <input
        ref="screenAdaptationInput"
        v-model="name"
        type="text"
        class="form-control form-input--underline"
        :placeholder="$t('Type name of adaptation')"
        @change="add"
        @blur="add"
      />
      <div>
        <span v-for="adaptation in adaptations" :key="adaptation" class="tag">
          <span v-text="adaptation" />
          <span class="tag__close" @click="remove(adaptation)"></span>
        </span>
      </div>
    </div>
  </div>
</template>
<i18n src="./translations.json"></i18n>

<script>
import categories from '@/constants/screenAdaptions';
import SingleSelectUnderline from '@/components/ui/singleselect/SingleSelectUnderline';

export default {
  components: {
    SingleSelectUnderline,
  },

  data() {
    const categoriesTranslated = categories.map((name) => ({
      name: this.$t(name),
      id: name,
    }));
    return {
      name: '',
      category: categoriesTranslated[0],
      adaptations: [],
      categories: categoriesTranslated,
    };
  },

  methods: {
    add() {
      const name = this.name.trim();

      if (this.empty(name)) {
        return;
      }

      if (!this.exists(name)) {
        const adaptation = {
          category: this.category.id,
          name,
        };

        this.adaptations.push(name);
        this.$emit('add', adaptation);
      }

      this.name = '';
    },

    remove(name) {
      this.adaptations = this.adaptations.filter(
        (adaptation) => adaptation !== name
      );

      this.$emit('remove', {
        category: this.category.id,
        name,
      });
    },

    exists(name) {
      return this.adaptations.some((adaptation) => adaptation === name);
    },

    empty(value) {
      return value === null || value === undefined || value === '';
    },
  },
};
</script>
